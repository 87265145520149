import { Button, Card, List, ListItem, Title } from "@tremor/react";
import { PlaidItemJSON, PlaidItemStats } from "../types";
import { LinkIcon } from "@heroicons/react/solid";

interface PlaidItemCardProps {
  item: PlaidItemJSON;
  itemStats: PlaidItemStats | undefined;
}
export default function PlaidItemCard({ item, itemStats }: PlaidItemCardProps) {
  return (
    <Card className="max-w-2xl mb-2">
      <Title>{item.institutionName}</Title>
      <List>
        <ListItem>
          <span>Institution Id</span> <span>{item.institutionId}</span>
        </ListItem>
        <ListItem>
          <span>Plaid Item ID</span>
          <Button
            variant="light"
            icon={LinkIcon}
            onClick={() =>
              window.open(
                `https://dashboard.plaid.com/activity/debugger/${item.itemId}`,
                "_blank"
              )
            }
          >
            {item.itemId}
          </Button>
        </ListItem>
        <ListItem>
          <span>Accounts</span> <span>{item.accounts.length}</span>
        </ListItem>
        <ListItem>
          {/* TODO implement transaction cursor reset */}
          <span>Transaction Cursor</span>{" "}
          <span
            className="ml-2"
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.transactionsCursor}
          </span>
        </ListItem>
        <ListItem>
          <span>Error Code</span> <span>{item.errorCode}</span>
        </ListItem>
        <ListItem>
          <span>Transactions Added</span>{" "}
          <span>{itemStats ? itemStats.addedCount : "-"}</span>
        </ListItem>
        <ListItem>
          <span>Transactions Modified</span>
          <span>{itemStats ? itemStats.modifiedCount : "-"}</span>
        </ListItem>
        <ListItem>
          <span>Transactions Removed</span>
          <span>{itemStats ? itemStats.removedCount : "-"}</span>
        </ListItem>
      </List>
    </Card>
  );
}
