import useAuth from "./hooks/useAuth";
import axios from "axios";
import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Title,
  Tab,
  TabList,
  TabGroup,
  TabPanels,
  Button,
} from "@tremor/react";
import { createRequestConfig } from "./utils";
import SubscriberPanel from "./panels/SubscriberPanel";
import CancellationPanel, { PanelProps } from "./panels/CancellationPanel";
import { StripeCustomer } from "./stripeTypes";
import { PlaidItemJSON, UserFeedbackJSON, UserJSON } from "./types";
import _ from "lodash";
import UserDebugPanel from "./panels/UserDebugPanel";

export default function Dashboard() {
  const { logout, getAccessTokenSilently } = useAuth();
  const [rawSubscriberData, setRawSubscriberData] = useState<
    PanelProps["subscriberData"] | null
  >(null);

  const handleAddressedChanged = useCallback(
    async (updatedFeedback: UserFeedbackJSON) => {
      if (!rawSubscriberData) {
        return;
      }

      const accessToken = await getAccessTokenSilently();
      axios.post(
        "/update-user-feedback",
        {
          userId: updatedFeedback.userId,
          feedbackId: updatedFeedback.id,
          addressed: !updatedFeedback.addressed,
        },
        createRequestConfig(accessToken)
      );

      const userFeedbackIndex = rawSubscriberData?.userFeedback.findIndex(
        (userFeedback) => userFeedback.id === updatedFeedback.id
      );

      if (userFeedbackIndex === -1) {
        return;
      }

      rawSubscriberData.userFeedback.splice(userFeedbackIndex, 1, {
        ...updatedFeedback,
        addressed: !updatedFeedback.addressed,
      });

      setRawSubscriberData({
        ...rawSubscriberData,
        userFeedback: [...rawSubscriberData.userFeedback],
      });
    },
    [rawSubscriberData, getAccessTokenSilently]
  );

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const res = await axios.get(
      "/admin-info",
      createRequestConfig(accessToken)
    );
    setRawSubscriberData(res.data);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerLookup = useMemo<Record<string, StripeCustomer>>(() => {
    if (!rawSubscriberData?.customers) {
      return {};
    }

    return rawSubscriberData.customers.reduce(
      (acc: Record<string, StripeCustomer>, cus: StripeCustomer) => {
        acc[cus.id] = cus;
        return acc;
      },
      {}
    );
  }, [rawSubscriberData]);

  const userLookupByEmail = useMemo<Record<string, UserJSON>>(() => {
    if (!rawSubscriberData?.users) {
      return {};
    }

    return rawSubscriberData.users.reduce(
      (acc: Record<string, UserJSON>, user: UserJSON) => {
        acc[user.email] = user;
        return acc;
      },
      {}
    );
  }, [rawSubscriberData]);

  const userLookupById = useMemo<Record<string, UserJSON>>(() => {
    if (!rawSubscriberData?.users) {
      return {};
    }

    return rawSubscriberData.users.reduce(
      (acc: Record<string, UserJSON>, user: UserJSON) => {
        acc[user.id] = user;
        return acc;
      },
      {}
    );
  }, [rawSubscriberData]);

  const plaidItemsGroupedByUserId = useMemo<
    Record<string, Array<PlaidItemJSON>>
  >(() => {
    if (!rawSubscriberData?.plaidItems) {
      return {};
    }

    return _.groupBy(rawSubscriberData.plaidItems, (item) => item.userId);
  }, [rawSubscriberData]);

  return (
    <div>
      <main className="py-12 px-2">
        <Title>NoFi Admin Dashboard</Title>

        <TabGroup className="mt-6">
          <TabList>
            <Tab>Subscribers</Tab>
            <Tab>Cancellations</Tab>
            <Tab>User Debug</Tab>
          </TabList>
          <TabPanels>
            <SubscriberPanel
              customerLookup={customerLookup}
              subscriberData={rawSubscriberData}
              userLookupByEmail={userLookupByEmail}
              userLookupById={userLookupById}
              plaidItemsGroupedByUserId={plaidItemsGroupedByUserId}
              onAddressedChanged={handleAddressedChanged}
            />
            <CancellationPanel
              customerLookup={customerLookup}
              subscriberData={rawSubscriberData}
              userLookupByEmail={userLookupByEmail}
              userLookupById={userLookupById}
              plaidItemsGroupedByUserId={plaidItemsGroupedByUserId}
              onAddressedChanged={handleAddressedChanged}
            />
            <UserDebugPanel
              customerLookup={customerLookup}
              subscriberData={rawSubscriberData}
              userLookupByEmail={userLookupByEmail}
              userLookupById={userLookupById}
              plaidItemsGroupedByUserId={plaidItemsGroupedByUserId}
              onAddressedChanged={handleAddressedChanged}
            />
          </TabPanels>
        </TabGroup>
        <Button className="mt-4" onClick={() => logout()}>
          Logout
        </Button>
      </main>
    </div>
  );
}
