import _ from "lodash";
import { startOfISOWeek, addWeeks, format } from "date-fns";
import { MailIcon } from "@heroicons/react/solid";
import { useMemo } from "react";
import {
  Card,
  Title,
  TabPanel,
  Metric,
  AreaChart,
  List,
  Text,
  Button,
} from "@tremor/react";
import { PanelProps } from "./CancellationPanel";

export default function SubscriberPanel({
  subscriberData,
  customerLookup,
  userLookupByEmail,
  plaidItemsGroupedByUserId,
  userLookupById,
  onAddressedChanged,
}: PanelProps) {
  const activeSubscriptionData = useMemo<{
    total: number;
    byWeek: Array<{ date: string; count: number }>;
  }>(() => {
    if (!subscriberData?.subscriptions.length) {
      return {
        total: 0,
        byWeek: [],
      };
    }

    const totalActiveSubscriptions = (subscriberData.subscriptions || [])
      .filter((sub: any) => sub.canceled_at === null)
      .map((sub: { created: number }) => {
        return {
          created: sub.created * 1000,
        };
      })

      .sort((a: any, b: any) => {
        if (a.created < b.created) {
          return -1;
        }
        return 1;
      });

    let subscriptionCount = 0;
    const defaultWeeks: Record<string, Array<unknown>> = {};
    let currentDate: Date = new Date(totalActiveSubscriptions[0].created);
    while (
      currentDate.valueOf() <=
      startOfISOWeek(addWeeks(new Date().valueOf(), 1)).valueOf()
    ) {
      defaultWeeks[startOfISOWeek(currentDate).toISOString()] = [];
      currentDate = addWeeks(currentDate, 1);
    }

    const subscriptionsByWeek = Object.entries({
      ...defaultWeeks,
      ..._.groupBy(totalActiveSubscriptions, (sub: any) => {
        return startOfISOWeek(new Date(sub.created)).toISOString();
      }),
    }).map(([date, subs]) => {
      subscriptionCount += subs.length;

      return {
        date,
        count: subscriptionCount,
      };
    });

    return {
      total: subscriptionCount,
      byWeek: subscriptionsByWeek,
    };
  }, [subscriberData]);

  return (
    <TabPanel>
      <div className="mt-6">
        <Card className="mb-2">
          <Title>Active Subscribers</Title>
          <Metric>{activeSubscriptionData.total}</Metric>
          <AreaChart
            className="mt-6"
            data={activeSubscriptionData.byWeek}
            index="date"
            categories={["count"]}
            colors={["emerald"]}
            yAxisWidth={40}
          />
        </Card>
        <Card>
          <Title>User Feedback</Title>
          <List>
            {(subscriberData?.userFeedback || [])
              .sort((a, b) => {
                if (a.timestamp > b.timestamp) {
                  return -1;
                }
                return 1;
              })
              .map((userFeedback) => (
                <div key={userFeedback.id} className="p-6">
                  <Text>
                    {userLookupById[userFeedback.userId]?.name ||
                      userLookupById[userFeedback.userId]?.email ||
                      "Unnamed user"}
                    {" · "}
                    {userFeedback.timestamp
                      ? format(userFeedback.timestamp, "MM/dd/yyyy")
                      : "N/A"}
                    <input
                      className="mx-2"
                      type="checkbox"
                      checked={userFeedback.addressed}
                      onChange={() => onAddressedChanged(userFeedback)}
                    />
                  </Text>
                  <Text className="py-6">{userFeedback.contents}</Text>
                  <Button
                    size="xs"
                    icon={MailIcon}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        userLookupById[userFeedback.userId]?.email
                      )
                    }
                  >
                    Copy Email
                  </Button>
                </div>
              ))}
          </List>
        </Card>
      </div>
    </TabPanel>
  );
}
