import axios from "axios";
import { Auth0Provider } from "@auth0/auth0-react";
import { ENVIRONMENT } from "./constants";

import "./App.css";
import Routes from "./Routes";

axios.defaults.baseURL =
  ENVIRONMENT === "production"
    ? "https://api.nofi.so"
    : "http://localhost:3001";

function App() {
  return (
    <Auth0Provider
      domain={
        ENVIRONMENT === "production"
          ? "nofi.us.auth0.com"
          : "dev-gdzw0igmjoyrwuju.us.auth0.com"
      }
      clientId={
        ENVIRONMENT === "production"
          ? "7RJkTZGcQxVqx4pFVsyqmUwhjuFQPff0"
          : "msHChnibK3VqMxNlqh1chtLqwA4TgKw2"
      }
      redirectUri={window.location.origin}
      audience="https://api.nofi.so"
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "1700px",
          margin: "auto",
        }}
      >
        <Routes />
      </div>
    </Auth0Provider>
  );
}

export default App;
