import useAuth from "./hooks/useAuth";
import Dashboard from "./Dashboard";
import Login from "./Login";

export default function RoutesComponent() {
  const { isLoading, isAuthenticated } = useAuth();
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    return <Dashboard />;
  }

  return <Login />;
}
