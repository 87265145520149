import { AxiosRequestConfig } from "axios";
// import {co} from "date-fns"
import { StripeSubscriptionJSON } from "./types";

export function createRequestConfig(
  accessToken: string,
  config?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    headers: {
      ...config?.headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
}

export function isUserSubscriptionValid(
  userSubscription: StripeSubscriptionJSON | null
): boolean {
  return (
    !!userSubscription &&
    userSubscription.subscriptionPeriodEnd > new Date().valueOf()
  );
}

export function isJobPolling(
  jobId: string | null,
  pollingLookup: { [jobId: string]: boolean }
): boolean {
  return !!jobId && !!pollingLookup[jobId];
}

export function uuidWithoutDashes(uuid: string): string {
  return uuid.replace("-", "");
}

export function isSmallOrLarger(): boolean {
  return window.innerWidth > 640;
}
export function isMediumOrLarger(): boolean {
  return window.innerWidth > 768;
}
export function isLargeOrLarger(): boolean {
  return window.innerWidth > 1023;
}
export function isExtraLarge(): boolean {
  return window.innerWidth > 1279;
}
