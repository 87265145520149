import _ from "lodash";
import { startOfISOWeek, format, addWeeks } from "date-fns";
import { useMemo } from "react";
import {
  Card,
  Title,
  Text,
  TabPanel,
  Metric,
  AreaChart,
  Flex,
  List,
  ListItem,
} from "@tremor/react";
import { StripeCustomer, StripeSubscription } from "../stripeTypes";
import { PlaidItemJSON, UserFeedbackJSON, UserJSON } from "../types";

interface CancelationFunnelWeek {
  date: string;
  count: number;
  usCount: number;
  usWithItemsCount: number;
  allCancelations: Array<StripeSubscription>;
  usCancelations: Array<StripeSubscription>;
  usWithItemsCancelations: Array<StripeSubscription>;
}
interface CancelationFunnelData {
  total: number;
  usTotal: number;
  usWithItemsTotal: number;
  byWeek: Array<CancelationFunnelWeek>;
}

export interface PanelProps {
  subscriberData: {
    subscriptions: Array<StripeSubscription>;
    customers: Array<StripeCustomer>;
    users: Array<UserJSON>;
    plaidItems: Array<PlaidItemJSON>;
    userFeedback: Array<UserFeedbackJSON>;
  } | null;
  customerLookup: Record<string, StripeCustomer>;
  userLookupByEmail: Record<string, UserJSON>;
  userLookupById: Record<string, UserJSON>;
  plaidItemsGroupedByUserId: Record<string, Array<PlaidItemJSON>>;
  onAddressedChanged: (feedback: UserFeedbackJSON) => Promise<void>;
}

export default function CancellationPanel({
  subscriberData,
  customerLookup,
  userLookupByEmail,
  plaidItemsGroupedByUserId,
}: PanelProps) {
  const canceledSubscriptionData = useMemo<CancelationFunnelData>(() => {
    if (!subscriberData?.subscriptions.length) {
      return {
        total: 0,
        usTotal: 0,
        usWithItemsTotal: 0,
        byWeek: [],
      };
    }

    const totalCancelledSubscriptions = (subscriberData.subscriptions || [])
      .filter((sub) => sub.canceled_at !== null)
      .map((sub) => {
        return {
          ...sub,
          canceled_at: sub.canceled_at! * 1000,
        };
      })

      .sort((a, b) => {
        if (a.created < b.created) {
          return -1;
        }
        return 1;
      });

    let subscriptionCount = 0;
    let usCancellationsCount = 0;
    let usWithItemsCancellationCount = 0;
    const defaultWeeks: Record<string, Array<StripeSubscription>> = {};
    let currentDate: Date = new Date(
      totalCancelledSubscriptions[0].created * 1000
    );
    while (
      currentDate.valueOf() <
      startOfISOWeek(
        addWeeks(
          totalCancelledSubscriptions[totalCancelledSubscriptions.length - 1]
            .created * 1000,
          1
        )
      ).valueOf()
    ) {
      defaultWeeks[startOfISOWeek(currentDate).toISOString()] = [];
      currentDate = addWeeks(currentDate, 1);
    }

    const cancellationsByWeek = Object.entries({
      ...defaultWeeks,
      ..._.groupBy(totalCancelledSubscriptions, (sub) => {
        return startOfISOWeek(new Date(sub.created * 1000)).toISOString();
      }),
    }).map<CancelationFunnelWeek>(([date, subs]) => {
      subscriptionCount += subs.length;

      // filter for US/CA only
      const usCancellations = subs.filter((sub) => {
        const customer = customerLookup[sub.customer];
        return ["US", "CA"].includes(customer?.address?.country || "");
      });
      usCancellationsCount += usCancellations.length;

      // filter for subs that added items
      const usWithItemsCancelations = usCancellations.filter((sub) => {
        const customer = customerLookup[sub.customer];
        if (!customer?.email) {
          return false;
        }

        const user = userLookupByEmail[customer.email];
        if (!user) {
          return false;
        }

        const plaidItems = plaidItemsGroupedByUserId[user.id];
        return plaidItems?.length;
      });
      usWithItemsCancellationCount += usWithItemsCancelations.length;

      return {
        date,
        count: subscriptionCount,
        usCount: usCancellationsCount,
        usWithItemsCount: usWithItemsCancellationCount,
        allCancelations: subs,
        usCancelations: usCancellations,
        usWithItemsCancelations: usWithItemsCancelations,
      };
    });

    return {
      total: subscriptionCount,
      usTotal: usCancellationsCount,
      usWithItemsTotal: usWithItemsCancellationCount,
      byWeek: cancellationsByWeek,
    };
  }, [
    subscriberData,
    customerLookup,
    plaidItemsGroupedByUserId,
    userLookupByEmail,
  ]);

  const relevantCanceledUsers = useMemo<Array<UserJSON>>(() => {
    return Object.values(canceledSubscriptionData.byWeek)
      .map<Array<UserJSON>>(({ usWithItemsCancelations }) => {
        return usWithItemsCancelations.map((sub) => {
          const customer = customerLookup[sub.customer];
          const user = userLookupByEmail[customer.email || ""];
          return user;
        });
      })
      .flat();
  }, [canceledSubscriptionData, userLookupByEmail, customerLookup]);

  return (
    <TabPanel>
      <div className="mt-6">
        <Card className="mb-2">
          <Title>Cancelation Funnel</Title>
          <Flex>
            <div>
              <Text>Canceled Subscriptions</Text>
              <Metric>{canceledSubscriptionData.total}</Metric>
            </div>
            <div>
              <Text>US/CA Canceled Subscriptions</Text>
              <Metric>{canceledSubscriptionData.usTotal}</Metric>
            </div>
            <div>
              <Text>US/CA With Items Canceled Subscriptions</Text>
              <Metric>{canceledSubscriptionData.usWithItemsTotal}</Metric>
            </div>
          </Flex>
          <AreaChart
            className="mt-6"
            data={canceledSubscriptionData.byWeek}
            index="date"
            categories={["count", "usCount", "usWithItemsCount"]}
            colors={["amber", "orange", "red"]}
            yAxisWidth={40}
          />
        </Card>
        <Card>
          <List>
            {relevantCanceledUsers
              .sort((a, b) => {
                if (
                  a.subscriptionPeriodEnd === null ||
                  b.subscriptionPeriodEnd === null
                ) {
                  return 0;
                }

                if (a.subscriptionPeriodEnd > b.subscriptionPeriodEnd) {
                  return -1;
                }
                return 1;
              })
              .map((user) => (
                <ListItem key={user.id}>
                  <span>
                    {user.subscriptionPeriodEnd
                      ? format(user.subscriptionPeriodEnd, "MM/dd/yyyy")
                      : "N/A"}
                  </span>
                  <span>{user.email}</span>
                </ListItem>
              ))}
          </List>
        </Card>
      </div>
    </TabPanel>
  );
}
