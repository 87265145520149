import { useEffect } from "react";
import useAuth from "./hooks/useAuth";

export default function Login() {
  const { loginWithRedirect } = useAuth();
  useEffect(() => {
    loginWithRedirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>Login</div>;
}
