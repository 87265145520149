import _ from "lodash";
import axios from "axios";
import { startOfISOWeek, addWeeks, format } from "date-fns";
import { LinkIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Title,
  TabPanel,
  Metric,
  AreaChart,
  List,
  Text,
  Button,
  TextInput,
  ListItem,
} from "@tremor/react";
import { PanelProps } from "./CancellationPanel";
import PlaidItemCard from "../components/PlaidItemCard";
import {
  BaseIntegrationJSON,
  PlaidItemJSON,
  PlaidItemStats,
  StripeSubscriptionJSON,
  UserJSON,
} from "../types";
import { createRequestConfig } from "../utils";
import useAuth from "../hooks/useAuth";

interface UserDebugInfo {
  userSubscription: StripeSubscriptionJSON | null;
  userIntegrations: Array<BaseIntegrationJSON>;
  userPlaidItems: Array<PlaidItemJSON>;
  plaidItemStats: Array<PlaidItemStats>;
}

export default function UserDebugPanel({
  subscriberData,
  customerLookup,
  userLookupByEmail,
  plaidItemsGroupedByUserId,
  userLookupById,
  onAddressedChanged,
}: PanelProps) {
  const { getAccessTokenSilently } = useAuth();
  const [userEmail, setUserEmail] = useState<string>("");
  const user: UserJSON | null = useMemo(
    () => userLookupByEmail[userEmail] || null,
    [userLookupByEmail, userEmail]
  );

  const [userDebugInfo, setUserDebugInfo] = useState<UserDebugInfo | null>(
    null
  );
  const fetchUserDebugInfo = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    const accessToken = await getAccessTokenSilently();
    const res = await axios.get(
      "/admin-user-debug",
      createRequestConfig(accessToken, { params: { targetUserId: user.id } })
    );
    if (!res.data.success) {
      console.error("Error getting user debug data");
      return;
    }

    setUserDebugInfo(res.data);
  }, [user, getAccessTokenSilently]);
  useEffect(() => {
    fetchUserDebugInfo();
  }, [user]);

  const itemStatsLookup = useMemo<Record<string, PlaidItemStats>>(
    () =>
      (userDebugInfo?.plaidItemStats || []).reduce((acc, itemStats) => {
        acc[itemStats.itemId] = itemStats;
        return acc;
      }, {} as Record<string, PlaidItemStats>),
    [userDebugInfo]
  );

  return (
    <TabPanel>
      <div className="mt-6">
        <Card className="max-w-2xl mb-2">
          <Title>Enter User Email</Title>
          <TextInput
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            className="max-w-xs my-2"
            placeholder="email"
          />
        </Card>
        {user ? (
          <>
            <Card className="max-w-2xl mb-2">
              <Title>User Information</Title>
              <List>
                <ListItem>
                  <span>UserId</span> <span>{user.id}</span>
                </ListItem>
                <ListItem>
                  <span>Email</span> <span>{user.email}</span>
                </ListItem>
                <ListItem>
                  <span>Transaction start date</span>
                  <span>
                    {user?.transactionStartDate
                      ? new Date(user.transactionStartDate).toDateString()
                      : "-"}
                  </span>
                </ListItem>
                <ListItem>
                  <span>Subscription valid through</span>
                  <span>
                    {userDebugInfo?.userSubscription?.subscriptionPeriodEnd
                      ? new Date(
                          userDebugInfo.userSubscription.subscriptionPeriodEnd
                        ).toDateString()
                      : "-"}
                  </span>
                </ListItem>
                <ListItem>
                  <span>Stripe subscription ID</span>
                  {userDebugInfo?.userSubscription?.id ? (
                    <Button
                      variant="light"
                      icon={LinkIcon}
                      onClick={() =>
                        window.open(
                          `https://dashboard.stripe.com/subscriptions/${userDebugInfo?.userSubscription?.id}`,
                          "_blank"
                        )
                      }
                    >
                      {userDebugInfo.userSubscription.id}
                    </Button>
                  ) : (
                    <span>-</span>
                  )}
                </ListItem>
                <ListItem>
                  <span>Stripe customer ID</span>
                  {userDebugInfo?.userSubscription?.customerId ? (
                    <Button
                      variant="light"
                      icon={LinkIcon}
                      onClick={() =>
                        window.open(
                          `https://dashboard.stripe.com/customers/${userDebugInfo?.userSubscription?.customerId}`,
                          "_blank"
                        )
                      }
                    >
                      {userDebugInfo.userSubscription.customerId}
                    </Button>
                  ) : (
                    <span>-</span>
                  )}
                </ListItem>
                <ListItem>
                  <span>Active subscription items</span>{" "}
                  <span>
                    {(userDebugInfo?.userSubscription?.items || [])
                      .map((item) => item.integrationType)
                      .join(",")}
                  </span>
                </ListItem>
              </List>
            </Card>
            <Card className="max-w-2xl mb-2">
              <Title>User Plaid Items</Title>
              <Metric>{userDebugInfo?.userPlaidItems.length}</Metric>
            </Card>
            {(userDebugInfo?.userPlaidItems || []).map((item) => (
              <PlaidItemCard
                key={item.itemId}
                item={item}
                itemStats={itemStatsLookup[item.itemId]}
              />
            ))}
          </>
        ) : (
          <Card>
            <p className="text-sm text-white">No user found.</p>
          </Card>
        )}
      </div>
    </TabPanel>
  );
}
